import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function seo({
  sitename,
  seoTitle,
  seoDesc,
  keywords,
  image,
  url,
  lang,
  meta,
  bodyClass,
  noIndex,
  noFollow,
}) {

  return (
    <Helmet
      htmlAttributes={{
        lang,
        itemscope: '',
        itemtype: 'http://schema.org/Article'
      }}
      bodyAttributes={{
        class: bodyClass
      }}
      title={seoTitle}
      titleTemplate={sitename ? `%s | ${sitename}` : seoTitle}
      meta={[
        {
          name: `robots`,
          content: noIndex,
        },
        {
          name: `robots`,
          content: noFollow,
        },
        {
          name: `description`,
          content: seoDesc,
        },
        {
          name: `keywords`,
          content: keywords?.join(),
        },
        image ? {
          name: `image`,
          content: `${image}`,
        } : {},
        {
          property: `og:title`,
          content: seoTitle,
        },
        seoDesc ? {
          property: `og:description`,
          content: seoDesc,
        } : {},
        {
          property: `og:type`,
          content: `article`,
        },
        {
          property: `og:url`,
          content: url,
        },
        image ? {
          property: `og:image`,
          content: `${image}`,
        } : {},
        {
          property: `og:site_name`,
          content: sitename,
        },
      ].concat(meta)}
    >
    </Helmet>
  )
}

seo.defaultProps = {
  layout: false,
  sitename: 'Edita Prima',
  lang: 'fi',
  meta: [],
  image: '',
  published: '',
  modified: '',
  pageTemplate: '',
  date: '',
}

seo.propTypes = {
  sitename: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDesc: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
  published: PropTypes.string,
  modified: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default seo;
