import React, { useEffect } from 'react';
import Header from '../header/header';
import Footer from '../footer/footer';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from "react-helmet";
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import 'mapbox-gl/dist/mapbox-gl.css';
import bgCases from '../../assets/images/Edita_Prima_photo_bg_cases_page.jpg';
import bgAbout from '../../assets/images/Edita_Prima_photo_bg_about_page.jpg';
import bgCareers from '../../assets/images/Edita_Prima_photo_bg_careers_page.jpg';
import bgNews from '../../assets/images/Edita_Prima_photo_bg_news_page.jpg';
import bgServices from '../../assets/images/Edita_Prima_photo_bg_services_page.jpg';
import bgContact from '../../assets/images/Edita_Prima_photo_bg_contact_page.jpg';
import bgCulture from '../../assets/images/Edita_Prima_photo_bg_culture_page.jpg';
import bgResponsibility from '../../assets/images/Edita_Prima_photo_bg_responsibility_page.jpg';

const Layout = ({ children, colorTheme, nodeLocale, pretitle }) => {
  let colorCategory = 'light-pink-theme';

  if (colorTheme && (colorTheme instanceof Array)) {
    if (colorTheme[0] === 'Red') {
      colorCategory = 'red-theme';
    } else if (colorTheme[0] === 'Green') {
      colorCategory = 'green-theme';
    } else if (colorTheme[0] === 'Blue') {
      colorCategory = 'blue-theme';
    } else if (colorTheme[0] === 'Violet') {
      colorCategory = 'violet-theme';
    } else if (colorTheme[0] === 'Pink') {
      colorCategory = 'pink-theme';
    } else if (colorTheme[0] === 'Turquoise') {
      colorCategory = 'turquoise-theme';
    } else if (colorTheme[0] === 'Orange') {
      colorCategory = 'orange-theme';
    } else if (colorTheme[0] === 'Yellow') {
      colorCategory = 'yellow-theme';
    } else if (colorTheme[0] === 'Caper') {
      colorCategory = 'caper-theme';
    } else {
      colorCategory = 'light-pink-theme';
    };
  }

  let locale = nodeLocale === 'en-US' ? 'en' : 'fi-FI';
  let bgType;
  //let bgHeight = '150vh';
  let bgHeight = '220vh';

  if (["työt", "cases"].includes(pretitle?.toLowerCase())) (
    bgType = bgCases
  )
  else if (["meistä", "about"].includes(pretitle?.toLowerCase())) (
    bgType = bgAbout
  )
  else if (["kulttuuri", "culture"].includes(pretitle?.toLowerCase())) (
    bgType = bgCulture
  )
  else if (["rekrytointi", "careers"].includes(pretitle?.toLowerCase())) (
    bgType = bgCareers
  )
  else if (["uutiset", "news"].includes(pretitle?.toLowerCase())) (
    bgType = bgNews
  )
  else if (["yhteystiedot", "contact"].includes(pretitle?.toLowerCase())) (
    bgType = bgContact
  ) 
  else if (["vastuullisuus", "sustainability"].includes(pretitle?.toLowerCase())) (
    bgType = bgResponsibility
  ) 
  else if (pretitle) {
    bgType = bgServices
  } 

  let sectionStyle = {};

  /*
  if (["kulttuuri", "culture"].includes(pretitle?.toLowerCase())) (
    bgHeight = '180vh'
  )
  */

  if (bgType) {
    sectionStyle = {
      backgroundImage: `url(${bgType})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      width: '100vw',
      height: bgHeight,
      marginBottom: "50"
    };
  }

  return (
    <StaticQuery
      query={graphql`
        query NavigationQuery {
          mainNavigation: allContentfulNavigation(filter: {navigationName: {eq: "Main navigation"}}) {
            nodes {
              node_locale 
              navigationLinks {
                ... on Node {
                  ... on ContentfulExternalLink {
                    id
                    node_locale
                    linkName
                    linkUrl
                  }
                  ... on ContentfulInternalLink {
                    id
                    linkName
                    linkToEntry {
                      ... on ContentfulPage {
                        id
                        node_locale
                        slug
                      }
                      ... on ContentfulSimplePage {
                        id
                        node_locale
                        slug
                      }
                    }
                    subNavigation{
                      ... on ContentfulInternalLink {
                        id
                        linkName
                        linkToEntry {
                          ... on ContentfulPage {
                            id
                            node_locale
                            slug
                          }
                          ... on ContentfulSimplePage {
                            id
                            node_locale
                            slug
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          footerNavigation: allContentfulNavigation(filter: {navigationName: {eq: "Footer navigation"}}) {
            nodes {
              node_locale 
              navigationLinks {
                ... on Node {
                  ... on ContentfulExternalLink {
                    id
                    node_locale
                    linkName
                    linkUrl
                  }
                  ... on ContentfulInternalLink {
                    id
                    linkName
                    linkToEntry {
                      ... on ContentfulPage {
                        id
                        node_locale
                        slug
                      }
                      ... on ContentfulSimplePage {
                        id
                        node_locale
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
          newsletterFormTexts: allContentfulNewsletterForm {
            edges {
              node {
                id
                texts {
                  email
                  subscribeLabel
                  bottomTextLine1
                  bottomTextLine2
                  failedMessage
                  invalidEmailMessage
                  thankyouMessage
                  submitButtonText
                }
                node_locale
              }
            }
          }
        }
    `}
      render={data => (
        <React.Fragment>
          <Helmet>
            <meta name="facebook-domain-verification" content="dk2leh0wac6e5k1shw55yg8xvg2ion" />
            <script id="usercentrics-cmp" data-settings-id="uwa7lMHfa" src="https://app.usercentrics.eu/browser-ui/latest/bundle.js" async></script>
            {/*<script type="text/javascript" async src="https://bot.leadoo.com/bot/dynamic/chat.js?company=o98vmjn6"></script>*/}
            {(locale === 'en') && <script type="text/javascript" async src="https://bot.leadoo.com/bot/chat.js?code=a35b997f"></script>}
            {(locale === 'fi-FI') && <script type="text/javascript" async src="https://bot.leadoo.com/bot/chat.js?code=13dbe6a9"></script>}
            
            <html lang={locale} />

          </Helmet>
          <div className={`site-container`}>
            <div className={`site-content ${colorCategory}`} style={sectionStyle}>
              <Header nodeLocale={nodeLocale} links={data.mainNavigation.nodes} colorCategory={colorCategory} locale={locale} />
              <main>{children}</main>
              <Footer
                nodeLocale={nodeLocale}
                links={data.footerNavigation.nodes}
                newsletterFormTexts={data.newsletterFormTexts}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    />
  )
}

export default injectIntl(Layout);