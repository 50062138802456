import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import Language from '../language/language';
import { injectIntl } from "gatsby-plugin-react-intl";
import Lottie from 'react-lottie';
import logoOpening from '../../assets/animations/Logo_opening_white.json';
import logoHover from '../../assets/animations/Logo_unwraping.json';
import logoScrollDown from '../../assets/animations/Logo_Folded_interaction.json';
import angleDown from '../../assets/images/angleDown.svg';
import angleUp from '../../assets/images/angleUp.svg';

const Header = ({ links, colorCategory, intl, nodeLocale }) => {
  const locales = {
    en: "en-US",
    fi: "fi-FI",
  }

  const node = links.filter(item => item.node_locale === locales[intl.locale]);
  let { navigationLinks } = node[0];

  // Add correct locale to url
  let locale = nodeLocale === 'en-US' ? 'en' : 'fi';

  // Translations
  const languageSwitcherTranslation = (locale === 'en' ? 'Change language' : 'Vaihda kieli');
  const navButtonTranslation = (locale === 'en' ? 'Open or close navigation' : 'Avaa tai sulje navigaatio');
  const subNavButtonTranslation = (locale === 'en' ? 'Open or close sub navigation' : 'Avaa tai sulje alanavigaatio')

  // Open and close navigation
  const [isActive, setIsActive] = useState(false);

  Header.handleClickOutside = () => setIsActive(false);

  // Open and close navigation
  const [subnavOpen, setSubnavOpen] = useState('');

  const toggleNavigation = () => {
    setIsActive(!isActive)
  };

  const [animationMode, setAnimationMode] = useState('basic');

  // Detect whether user has scrolled the page down by 10px
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      if (window.pageYOffset > 10) {
        setTop(false);
        setAnimationMode('scrolldown');
      } else {
        setTop(true);
        setAnimationMode('basic');
      }
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: logoOpening,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMid meet'
    }
  };

  const hoverOptions = {
    loop: false,
    autoplay: true,
    animationData: logoHover,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMid meet'
    }
  };

  const scrollDownOptions = {
    loop: false,
    autoplay: true,
    animationData: logoScrollDown,
    rendererSettings: {
      preserveAspectRatio: 'xMinYMid meet'
    }
  };

  return (
    <header className={`site-navigation px-4 sm:px-6 md:px-11 lg-max:flex lg-max:items-center fixed top-0 left-0 w-full h-16 xs:h-24 z-50 transform transition-all duration-300 in-expo ${!top && 'bg-blackcurrant'} ${colorCategory === 'light-pink-theme' ? 'bg-blackcurrant' : ''}`}>
      <nav className="flex flex-wrap justify-center w-full small-text text-black">
        <div className="w-full">
          <div className="absolute lg:hidden top-5 xs:top-8 lg:top-6 left-4 sm:left-6 md:left-11 lg:order-2 w-auto">
            <Link to={`/${locale}/`} className="site-navigation__logo text-white">
              {animationMode === 'basic' &&
                <Lottie
                  options={defaultOptions}
                />
              }
              {animationMode === 'scrolldown' &&
                <Lottie
                  options={scrollDownOptions}
                />
              }
            </Link>
          </div>
          <div className="absolute hidden lg:block top-5 xs:top-8 lg:top-6 left-4 sm:left-6 md:left-11 lg:order-2 w-auto">
            <Link to={`/${locale}/`} className="site-navigation__logo text-white" onMouseOver={() => setAnimationMode('hover')}>
              {animationMode === 'basic' &&
                <Lottie
                  options={defaultOptions}
                />
              }
              {animationMode === 'hover' &&
                <Lottie
                  options={hoverOptions}
                />
              }
              {animationMode === 'scrolldown' &&
                <Lottie
                  options={scrollDownOptions}
                />
              }
            </Link>
          </div>

          <div className="block">
            <button aria-label={languageSwitcherTranslation} className="absolute right-17 sm:right-19 md:right-29 top-3.5 xs:top-7.5 z-50 flex">
              <Language locale={locale} />
            </button>

            <button aria-label={navButtonTranslation} className={`${isActive ? 'opened' : ''} menu absolute top-0 right-1.5 sm:right-3.5 md:right-8 z-50 w-16 h-16 xs:w-24 xs:h-24 flex items-center justify-end cursor-pointer focus:outline-none`} onClick={toggleNavigation}>
              <svg className="w-16 h-16 xs:w-24 xs:h-24" viewBox="0 0 100 100">
                <path class="menu__line menu__line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path class="menu__line menu__line2" d="M 20,50 H 80" />
                <path class="menu__line menu__line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </button>
          </div>
        </div>

        <aside className={`navigation max-w-sm w-full min-h-screen fixed top-0 right-0 z-40 bg-blackcurrant shadow-sm transform transition-all ${isActive ? "translate-x-0" : "translate-x-full"}`}>
          <div className="min-h-screen relative">
            <div className="py-24 absolute top-0 left-0 w-full overflow-x-hidden overflow-y-auto h-full no-scrollbar">
              <div>
                <div className="overflow-y-auto h-full flex">
                  <ul className="px-12 sm:px-18">
                    {navigationLinks?.map((link) => {
                      // Check if link is external or internal
                      let linkItem;

                      if (!link.subNavigation) {
                        if (link.linkUrl) {
                          linkItem = <a className="navigation__link py-1.5" href={link.linkUrl} target="_blank"><span className="hidden sm:hover:block">→</span> {link.linkName}</a>
                        } else if (link.linkName === 'Home' || link.linkName === 'Etusivu') {
                          linkItem = <Link className="navigation__link py-1.5" to={`/${locale}/`}><span className="hidden sm:hover:block">→</span> {link.linkName}</Link>
                        } else {
                          linkItem = <Link className="navigation__link py-1.5" to={`/${locale}/${link.linkToEntry.slug}/`}><span className="hidden sm:hover:block">→</span> {link.linkName}</Link>
                        }
                      } else {
                        linkItem =
                          <div className="navigation__link subnavigation-open-container">
                            {subnavOpen === link.linkName

                              ?
                              <div className=" subnavigation-open flex items-center" onClick={() => setSubnavOpen('')}>
                                <span className="hidden">→</span>
                                {link.linkName}
                                <div className="mx-2 p-1 h-6 w-6">
                                  <img className="w-full height-full" src={angleUp} />
                                </div>
                              </div>
                              :
                              <div className="subnavigation-close flex items-center" onClick={() => setSubnavOpen(link.linkName)}>
                                <span className="hidden">→</span>
                                {link.linkName}
                                <button aria-label={subNavButtonTranslation} className="mx-2 p-1 h-6 w-6">
                                  <img className="w-full height-full" alt="" src={angleDown} />
                                </button>
                              </div>
                            }
                          </div>
                      }

                      return (
                        <>
                          <li>
                            {linkItem}
                          </li>

                          {(link.subNavigation && subnavOpen === link.linkName) &&
                            <li>
                              <Link className="navigation__link py-1.5" to={`/${locale}/${link.linkToEntry.slug}`}>
                                {/* <span className="hidden">→</span> */}
                                <div className="px-4 pt-4 navigation__sublink hover:text-springGreen">{locale === 'en' ? 'Overview' : 'Esittely'}</div>
                              </Link>
                            </li>}

                          {(link.subNavigation && subnavOpen === link.linkName) &&
                            <ul className="px-4 pb-4">
                              {link.subNavigation?.map((sublink) =>
                                <li>
                                  <Link
                                    className="navigation__sublink subnavigation py-2"
                                    to={`/${locale}/${sublink.linkToEntry.slug}`}
                                  >
                                    <span className="hidden">→</span> {sublink.linkName}
                                  </Link>
                                </li>
                              )}
                            </ul>
                          }
                        </>
                      )
                    })}
                  </ul>

                </div>
              </div>

            </div>
          </div>
        </aside>
      </nav>
    </header>
  );
}

export default injectIntl(Header);
