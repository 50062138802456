import React, { useState, useEffect } from 'react'
import { checkValidity } from '../../shared/utility';

const NewsletterSubscription = ({newsletterFormTexts, nodeLocale}) => {
  const [newsletterTexts, setNewsletterTexts] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [formValues, setFormValues] = useState({
    email: ''
  });

  useEffect(() => {
    if (newsletterFormTexts) {
      setNewsletterTexts(newsletterFormTexts.edges.filter(item => item.node.node_locale === nodeLocale)[0].node.texts);
    }
  }, [newsletterFormTexts]);

  function handleInputChange(e) {
    e.persist();
    setErrorMessage('');
    setSuccessMessage('');
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }));

    switch (e.target.name) {
      case 'email':
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessage(newsletterTexts?.invalidEmailMessage);
        } else {
          setErrorMessage('');
        }
        break;
      default:
        break;
    }
  }

  const handleSubmit = async event => {
    event.preventDefault();
    const data = new FormData(event.target)
    const success = await fetch("https://edita660.activehosted.com/proc.php", {
      method: "POST",
      body: data,
      mode: "no-cors",
    });

    if (success) {
      if (typeof window !== 'undefined') {
        // Subscribe to Footer's newsletter, datalayer
        // When the email is sent successfully
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event' : 'newsletterSubscibe',
        'position' : 'footer'
        });
      }
      formValues.email = "";
      setSuccessMessage(newsletterTexts?.thankyouMessage);
    } else {
      setErrorMessage(newsletterTexts?.failedMessage);
    } 
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
      <input type="hidden" name="u" value="8" />
      <input type="hidden" name="f" value="8" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />
      <input type="hidden" name="or" value="f1221d513fe3ab48fb5e6a2dc3d82af0" />

      <fieldset className="mx-auto flex lg-max:flex-col md-max:justify-center lg:items-start">
        <legend className="medium-title-book mb-4 text-white">{newsletterTexts?.subscribeLabel}</legend>
        <input onChange={handleInputChange} value={formValues.email} type="email" id="email" name="email" className="button-text w-full lg:flex-1 lg-max:mb-4 px-4 py-3 h-10-5 border border-transparent rounded-3xl focus:outline-none placeholder-blackcurrant" placeholder={newsletterTexts?.email} required />
        <button className="btn-sm btn-sm--heliotrope lg:ml-3 h-10-5 self-center rounded-3xl text-blackcurrant uppercase">{newsletterTexts?.submitButtonText}</button>
      </fieldset>
      {!!successMessage &&
        <p className="mt-3 text-white">{successMessage}</p>
      }
      {!!errorMessage &&
        <p className="mt-3 text-white">{errorMessage}</p>
      }
    </form>
  )
}

export default NewsletterSubscription